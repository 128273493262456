import * as Sentry from "@sentry/sveltekit";

Sentry.init({
    dsn: "https://fa759fa1b9ea4bebac5efb336965699f@bugs.hardcode.at/2",
    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    tracesSampleRate: 1.0,
    tracePropagationTargets: [],
});

const myErrorHandler = ({ error, event }) => {
    console.error("An error occurred on the client side:", error, event);
};

export const handleError = Sentry.handleErrorWithSentry(myErrorHandler);

// or alternatively, if you don't have a custom error handler:
// export const handleError = handleErrorWithSentry();
